import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisLanguageSelection from "@/components/language-selection"
import { TRANSLATION_PREFERENCES } from "../../constants"
export default {
  name      : "Languages",
  components: { ThemisLanguageSelection },
  data() {
    return {
      channelLanguages: new Array()
    }
  },
  computed: {
    ...mapGetters({
      channels                           : "channels/channels",
      languages                          : "languages/languages",
      translationConfigurationsOfChannels: "channels/translationConfigurationsOfChannels",
      reporterLanguage                   : "auth/reporterLanguage",
      isAiSpeechToTextEnabled            : "auth/isAiSpeechToTextEnabled",
      isClientAiSpeechToTextEnabled      : "auth/isClientAiSpeechToTextEnabled",
      translationPreferenceId            : "auth/translationPreferenceId"
    }),
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    hackForWatchingMultipleProperties() {
      return [this.languagesMap, this.translationConfigurationsOfChannels]
    }
  },
  methods: {
    ...mapActions({
      suppressNotification: "shared/suppressNotification"
    }),
    ...mapMutations({
      setReporterLanguage: "auth/setReporterLanguage"
    }),
    changeReporterLanguage(language) {
      this.showLanguagesDialog = false
      this.setReporterLanguage(language)

      this.$router.push({
        name  : this.channel.consent ? "consent" : "organisation-code",
        params: {
          locale : language.shortName,
          channel: this.$route.params.channel
        }
      })
    },
    computeChannelLanguages() {
      this.channelLanguages                  = []
      const effectiveTranslationPreferenceId =
        this.channel.overrideTranslationPreference
          ? this.channel.translationPreferenceId
          : this.translationPreferenceId

      if (this.translationConfigurationsOfChannels[this.channel.id]) {
        for (const translationConfiguration of this.translationConfigurationsOfChannels[this.channel.id]) {
          const language = this.languagesMap[translationConfiguration.languageId]

          if (!language)
            continue

          const isHumanTranslatable = TRANSLATION_PREFERENCES.HUMAN_ONLY === effectiveTranslationPreferenceId
            && language.humanTranslatable
          const isMachineOnly       = TRANSLATION_PREFERENCES.MACHINE_ONLY === effectiveTranslationPreferenceId
            && language.machineTranslatable
          const isMachinePreferred  = TRANSLATION_PREFERENCES.MACHINE_PREFERRED === effectiveTranslationPreferenceId
            && (language.machineTranslatable || language.humanTranslatable)
          const noTranslation       = TRANSLATION_PREFERENCES.NO_TRANSLATION === effectiveTranslationPreferenceId

          if (isHumanTranslatable || isMachineOnly || isMachinePreferred || noTranslation) {
            this.channelLanguages.push(language)
          }
        }
      }
    }
  },
  watch: {
    hackForWatchingMultipleProperties: {
      immediate: true,
      handler  : function() {
        this.computeChannelLanguages()
      }
    }
  }
}